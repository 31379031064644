export default (message) => {
  const alertElement = document.querySelector('[data-js-selector="flash-wrapper"]');

  if (alertElement && message) {
    clearFlashMessages(alertElement);

    const jsAlert = document.createElement('div');
    const attentionIcon = document.createElement('i');
    jsAlert.classList.add('alert', 'alert-danger', 'alert--fixed');
    jsAlert.dataset.jsSelector = 'alert';
    attentionIcon.classList.add('fa', 'fa-exclamation-triangle');
    jsAlert.appendChild(attentionIcon);
    const messageNode = document.createTextNode(message);
    jsAlert.appendChild(messageNode);

    alertElement.appendChild(jsAlert);
    setTimeout(() => {
      jsAlert.remove();
    }, 3500);
  }
};

const clearFlashMessages = (alertElement) => {
  const jsAlerts = alertElement.querySelectorAll('[data-js-selector="alert"]');
  jsAlerts.forEach((alert) => {
    alert.remove();
  });
};
