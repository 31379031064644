export default function socialMediaSharer(platform, url, hashtags = '') {
  return {
    handleClick() {
      switch (platform.toLowerCase()) {
        case 'facebook':
          window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${url}`,
            'Facebook',
            'height=285,width=550,resizable=1'
          );
          break;
        case 'twitter':
          url = hashtags ? url.concat(`&hashtags=${hashtags}`) : url;
          window.open(
            `https://twitter.com/intent/tweet?text=&url=${url}`,
            'Twitter',
            'height=285,width=550,resizable=1'
          );
          break;
      }
    },
  };
}
