import setFlashMessage from '~/components/utils/flashMessage';
import axios from 'axios';

(() => {
  const entryTileLikeButtons = document.querySelectorAll('[data-js-selector="toggle-vote"]');
  entryTileLikeButtons.forEach((el) => {
    el.addEventListener('click', (evt) => toggleVote(evt));
  });
})();

const toggleVote = (evt) => {
  const entryId = evt.currentTarget.dataset.entryId;
  if (entryId) {
    axios
      .post(`entries/${entryId}/toggle_like.json`)
      .then((response) => {
        const data = response.data;
        if (data.action === 'voted') {
          hideVoteElements(entryId);
        } else if (data.action === 'voted_do_redirect') {
          hideVoteElements(entryId);
          window.location = `entries/${entryId}/thank_you_for_voting`;
        } else if (data.trigger === 'unvoted') {
          showVoteElements(entryId);
        }

        updateVotesCount(entryId, data.count);
        updateTopicVotesCount(data.topic_votes_count);
        if (data.flash_notice) setFlashMessage(data.flash_notice);
      })
      .catch((error) => {
        setFlashMessage('Something went wrong, please try again or contact support.');
        console.error(error);
      });
  } else {
    console.error('Could not retrieve entry id.');
  }
};

const hideVoteElements = (entryId) => {
  document.querySelectorAll(`#entry_${entryId} .can-vote`).forEach((el) => el.classList.add('hidden'));

  document.querySelectorAll(`#entry_${entryId} .cannot-vote`).forEach((el) => el.classList.remove('hidden'));
};

const showVoteElements = (entryId) => {
  document.querySelectorAll(`#entry_${entryId} .can-vote`).forEach((el) => el.classList.remove('hidden'));

  document.querySelectorAll(`#entry_${entryId} .cannot-vote`).forEach((el) => el.classList.add('hidden'));
};

const updateVotesCount = (entryId, count) => {
  const counters = document.querySelectorAll(`#entry_${entryId} .votes-count, #counters_${entryId} .votes-count`);
  counters.forEach((el) => (el.innerText = count));
};

const updateTopicVotesCount = (count) => {
  const counter = document.querySelector('[data-js-selector="topic-likes-counter"]');
  if (counter) counter.innerText = count;
};

export { updateVotesCount };
