(() => {
  const registrationOptions = document.querySelectorAll('input[name="participant-registers-as"]');
  if (registrationOptions.length == 0) return;
  const organizationInputWrapper = document.querySelector('[data-participant-registers-as="organization-wrapper"]');
  const organizationInput = organizationInputWrapper.querySelector('[data-participant-registers-as="organization"]');
  const firstNameInput = document.querySelector('[data-participant-input="first-name"]');

  registrationOptions.forEach((option) => {
    if (option.value == 'participant' && option.hasAttribute('checked')) {
      organizationInputWrapper.classList.add('hidden');
      organizationInput.setAttribute('value', '');
      firstNameInput.focus();
    }
    option.addEventListener('change', toggleOrganizationField, true);
  });
})();

function toggleOrganizationField(event) {
  const organizationInputWrapper = document.querySelector('[data-participant-registers-as="organization-wrapper"]');
  const organizationInput = organizationInputWrapper.querySelector('[data-participant-registers-as="organization"]');
  const firstNameInput = document.querySelector('[data-participant-input="first-name"]');

  if (event.currentTarget.value == 'participant') {
    organizationInputWrapper.classList.add('hidden');
    organizationInput.setAttribute('value', '');
    firstNameInput.focus();
  }
  if (event.currentTarget.value == 'organization') {
    organizationInputWrapper.classList.remove('hidden');
    organizationInput.focus();
  }
}
