export default function entriesSearch(translations) {
  return {
    translations: JSON.parse(translations),
    // For search and topics, the first element to get focus using the arrow keys is the crosshair (to remove current input).
    // But by default this crosshair is not visible since there's no input yet.
    firstElementVisible: { search: false, topics: false, sort: true },
    selected: { topics: [], sort: 'random' },
    currentFocus: -1,
    items: {
      topics: this.$refs.topicFilter.querySelectorAll('[role="menuitem"]'),
      sort: this.$refs.sortFilter.querySelectorAll('[role="menuitem"]'),
    },
    trigger: {
      topics: this.$refs.topicFilter.querySelector('summary'),
      sort: this.$refs.sortFilter.querySelector('summary'),
    },
    activeView: 'grid',

    init() {
      // the search field
      this.$refs.searchInput.addEventListener('input', () => {
        this.firstElementVisible.search = this.$refs.searchInput.value.length > 0;
      });

      // the topics dropdown
      this.$refs.topicFilter.addEventListener('toggle', () => {
        if (!this.$refs.topicFilter.open) this.search();
      });
      this.$refs.topicFilter.addEventListener('input', () => {
        this.firstElementVisible.topics = this.selected.topics.length > 0;
      });

      // the sort dropdown
      this.$refs.sortFilter.addEventListener('change', () => {
        this.selected.sort = this.$refs.sortFilter.querySelector('input:checked').value;
        this.search();
        this.$refs.sortFilter.open = false;
      });
    },

    search() {
      this.$refs.searchForm.requestSubmit();
    },

    clearSearch() {
      this.$refs.searchInput.value = '';
      this.$refs.searchInput.focus();
      this.search();
    },

    clearTopics() {
      this.$refs.topicFilter.open = false;
      this.selected.topics.forEach((topic) => {
        topic.checked = false;
      });
      this.firstElementVisible.topics = false;
      this.search();
    },

    selectItem(type) {
      let item = this.items[type][this.currentFocus].querySelector('input');
      if (this.currentFocus > -1) item.checked = !item.checked;
      this.selected[type] = type === 'topics' ? this.$refs.topicFilter.querySelectorAll('input:checked') : item.value;
      this.firstElementVisible[type] = type === 'topics' ? this.selected[type].length > 0 : true;
      if (type === 'sort') this.$refs.sortFilter.open = !this.$refs.sortFilter.open;
    },

    deselectAllTopics() {
      this.selected.topics.forEach((topic) => {
        topic.checked = false;
      });
      this.firstElementVisible.topics = false;
      this.resetFocus('topics');
      this.$refs.topicFilter.open = false;
    },

    selectedTopicsText() {
      return `${this.translations.selected} (${this.selected.topics.length})`;
    },

    previousDropdownItem(type) {
      if (this.currentFocus > (this.firstElementVisible[type] ? 0 : 1)) {
        this.items[type][this.currentFocus].classList.remove('dropdown__item--focus');
        this.currentFocus--;
        this.items[type][this.currentFocus].classList.add('dropdown__item--focus');
      }
    },

    nextDropdownItem(type) {
      if (this.trigger[type].matches(':focus')) this.trigger[type].classList.add('summary--unfocused');

      if (this.currentFocus === -1 && !this.firstElementVisible[type]) {
        this.currentFocus = 1;
        this.items[type][this.currentFocus].classList.add('dropdown__item--focus');
      } else {
        if (this.currentFocus < this.items[type].length - 1) {
          if (this.currentFocus > -1) this.items[type][this.currentFocus].classList.remove('dropdown__item--focus');
          this.currentFocus++;
          this.items[type][this.currentFocus].classList.add('dropdown__item--focus');
        }
      }
    },

    resetFocus(type) {
      if (this.items[type][this.currentFocus]) {
        this.items[type][this.currentFocus].classList.remove('dropdown__item--focus');
      }
      this.trigger[type].classList.remove('summary--unfocused');
      this.currentFocus = -1;
    },

    // Pressing Tab (9), Enter (13), Shift (16) or Space (32) should not trigger a search after 750ms.
    // Enter is here to prevent triggering search when pressing Enter in the skiplinks.
    // Pressing Enter in the search input will still work as expected (= post the search form).
    searchInput: {
      ['@keydown.debounce.750ms']() {
        if ([9, 13, 16, 32].includes(this.$event.keyCode)) return;
        this.search();
      },
    },

    clearInputButton: {
      ['@click']() {
        this.clearSearch();
      },
      ['@keydown.enter.stop']() {
        this.clearSearch();
      },
      ['x-show']() {
        return this.firstElementVisible['search'];
      },
    },

    clearTopicsButton: {
      ['@click.prevent']() {
        this.clearTopics();
      },
      ['x-show']() {
        return this.firstElementVisible.topics;
      },
    },

    topicsPlaceholder: {
      ['x-text']() {
        return this.firstElementVisible.topics ? this.selectedTopicsText() : this.translations.placeholder;
      },
      ['x-bind:class']() {
        return this.firstElementVisible.topics ? 'topic-filter__selected-topics' : '';
      },
      ['@keyup.tab']() {
        this.$refs.topicFilter.open = false;
        this.search();
      },
    },

    sortPlaceholder: {
      ['x-text']() {
        return this.translations.filters[this.selected.sort];
      },
      ['@click']() {
        this.$refs.sortFilter.open = false;
      },
      ['@keyup.enter']() {
        this.search();
      },
      ['@keyup.tab']() {
        this.search();
      },
    },

    topicDropdown: {
      ['@click']() {
        this.selected.topics = this.$refs.topicFilter.querySelectorAll('input:checked');
      },
      ['@click.outside']() {
        this.$refs.topicFilter.open = false;
      },
      ['@keydown.arrow-up.prevent']() {
        if (this.$refs.topicFilter.open) this.previousDropdownItem('topics');
      },
      ['@keydown.arrow-down.prevent']() {
        if (this.$refs.topicFilter.open) this.nextDropdownItem('topics');
      },
      ['@keydown.tab']() {
        this.resetFocus('topics');
        this.$refs.topicFilter.open = false;
      },
      ['@keydown.space.prevent']() {
        if (this.$refs.topicFilter.open) this.resetFocus('topics');
        this.$refs.topicFilter.open = !this.$refs.topicFilter.open;
      },
      ['@keydown.enter.prevent.stop']() {
        if (this.currentFocus !== 0) {
          this.selectItem('topics');
        } else if (this.currentFocus === 0) {
          this.deselectAllTopics();
        }
      },
    },

    sortDropdown: {
      ['x-cloak']() {
        return true;
      },
      ['x-transition']() {
        return true;
      },
      ['x-show']() {
        return this.activeView === 'grid';
      },
      ['@click.outside']() {
        this.$refs.sortFilter.open = false;
      },
      ['@keydown.arrow-up.prevent']() {
        if (this.$refs.sortFilter.open) this.previousDropdownItem('sort');
      },
      ['@keydown.arrow-down.prevent']() {
        if (this.$refs.sortFilter.open) this.nextDropdownItem('sort');
      },
      ['@keydown.tab']() {
        this.resetFocus('sort');
        this.$refs.sortFilter.open = false;
      },
      ['@keydown.space.prevent']() {
        if (this.$refs.sortFilter.open) this.resetFocus('sort');
        this.$refs.sortFilter.open = !this.$refs.sortFilter.open;
      },
      ['@keydown.enter.stop.prevent']() {
        if (this.currentFocus > -1) {
          this.selectItem('sort');
          this.resetFocus('sort');
        }
      },
    },

    shuffleTrigger: {
      ['@click']() {
        this.search();
      },
      ['@keydown.enter']() {
        this.search();
      },
    },
  };
}
