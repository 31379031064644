document.addEventListener('turbo:load', () => {
  const searchButton = document.querySelector("[data-js-selector='search-button']");
  const searchWrapper = document.querySelector("[data-js-selector='search-bar']");

  if (!searchButton || !searchWrapper) return;

  const observer = new MutationObserver(() => {
    if (searchWrapper.classList.contains('is-open')) {
      document.querySelector('header input[name="q"]').focus();
    }
  });

  observer.observe(searchWrapper, { attributes: true });

  const searchInput = {
    open: () => {
      searchButton.classList.add('is-open');
      searchWrapper.classList.add('is-open');
    },
    close: () => {
      searchButton.classList.remove('is-open');
      searchWrapper.classList.remove('is-open');
    },
  };

  searchButton.addEventListener('click', () => {
    const isOpen = searchButton.classList.contains('is-open');

    if (isOpen) searchInput.close();
    else searchInput.open();
  });

  searchWrapper.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      searchInput.close();
      searchButton.focus();
    }
  });
});
