export default function newsItemsCategories() {
  return {
    init() {
      this.filter_els = this.$el.querySelectorAll('[data-js-selector="news-items-filter-button"]');
    },

    activateFilter(el) {
      this.filter_els.forEach((filter) => {
        filter.classList.remove('news-items__filter-button--active');
      });
      el.classList.add('news-items__filter-button--active');
    },

    filters: {
      ['@click']() {
        this.activateFilter(this.$event.target);
      },
    }

  }
}
