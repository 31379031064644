export default function cookieConsent(googleAnalyticsKey) {
  const COOKIE_NAME = 'bpart_cookie_consent_analytics';
  const COOKIE_EXPIRE = 31536000; // 1 year

  return {
    show: null,
    consented: null,
    googleAnalyticsKey: googleAnalyticsKey,

    init() {
      this.$watch('consented', (value) => this.consentChanged(value));
      this.show = !this.isAnalyticsCookieSet();
      this.consented = this.isAnalyticsAllowed();
    },

    consentChanged(consent) {
      switch (consent) {
        case true:
          this.setGTag();
          this.setAnalyticsCookieGranted();
          this.setGoogleAnalytics();
          break;
        case false:
          this.removeGoogleAnalytics();
          this.setAnalyticsCookieDenied();
          break;
      }
    },

    handleClick(consent = { consented: false }) {
      this.consented = consent.consented;
      this.show = false;
    },

    gtagUrl() {
      if (!this.googleAnalyticsKey) return null;
      return `https://www.googletagmanager.com/gtag/js?id=${this.googleAnalyticsKey}`;
    },

    setGTag() {
      if (!this.googleAnalyticsKey) return;

      this.gtag('consent', 'default', {
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        ad_storage: 'denied',
        analytics_storage: 'denied',
        wait_for_update: 500,
      });
      this.gtag('js', new Date());
      this.gtag('config', googleAnalyticsKey, { anonymize_ip: true });
    },

    setGoogleAnalytics() {
      if (this.isGoogleAnalyticsSet()) return;

      this.gtag('consent', 'update', {
        analytics_storage: 'granted',
      });

      const scriptEl = document.createElement('script');
      scriptEl.type = 'text/javascript';
      scriptEl.src = this.gtagUrl();
      scriptEl.async = 'true';
      scriptEl.dataset.turboTrack = 'reload';

      document.querySelector('head').append(scriptEl);
    },

    isGoogleAnalyticsSet() {
      if (this.gtagUrl() !== null) {
        return document.querySelector(`script[src="${this.gtagUrl()}"]`) !== null;
      }
    },

    removeGoogleAnalytics() {
      const scriptEl = document.querySelector(`script[src="${this.gtagUrl()}"]`);
      if (scriptEl) scriptEl.remove();
    },

    setAnalyticsCookieGranted() {
      if (this.isAnalyticsCookieGranted()) return;
      document.cookie = `${COOKIE_NAME}=granted; max-age=${COOKIE_EXPIRE}; path=/`;
    },

    setAnalyticsCookieDenied() {
      if (this.isAnalyticsCookieDenied()) return;
      document.cookie = `${COOKIE_NAME}=denied; max-age=${COOKIE_EXPIRE}; path=/`;
    },

    isAnalyticsAllowed() {
      if (this.isAnalyticsCookieDenied()) return false;
      if (this.isAnalyticsCookieGranted()) return true;
      return null;
    },

    isAnalyticsCookieSet() {
      return document.cookie.split(';').some((item) => item.trim().startsWith(COOKIE_NAME));
    },

    isAnalyticsCookieDenied() {
      return document.cookie.split(';').some((item) => item.trim().startsWith(`${COOKIE_NAME}=denied`));
    },

    isAnalyticsCookieGranted() {
      return document.cookie.split(';').some((item) => item.trim().startsWith(`${COOKIE_NAME}=granted`));
    },

    gtag() {
      window.dataLayer = window.dataLayer ||= [];
      dataLayer.push(arguments);
    },
  };
}
