import { sha3_512 } from 'js-sha3';

export default function voterRRN() {
  return {
    privacyPolicyChecked: false,

    init() {
      const form = this.$refs.form;
      const rrn = this.$refs.rrn;

      if (form && rrn) {
        this.formatAsRRN(rrn);
        this.hashRRN(form, rrn);
      }
    },

    hashRRN(form, rrn) {
      form.addEventListener('submit', (evt) => {
        const hashedRRN = this.$refs.hashedRRN;
        const filteredRRN = this.onlyDigits(rrn.value);

        hashedRRN.value = sha3_512(filteredRRN);
      });
    },

    formatAsRRN(rrn) {
      rrn.addEventListener('input', (evt) => {
        const value = this.onlyDigits(evt.target.value);
        const rrn1 = [value.slice(0, 2), value.slice(2, 4), value.slice(4, 6)].filter((value) => !!value).join('.');
        const rrn2 = [value.slice(6, 9), value.slice(9, 11)].filter((value) => !!value).join('.');
        rrn.value = [rrn1, rrn2].filter((value) => !!value).join('-');
      });
    },

    onlyDigits(input) {
      return input.replaceAll(/\D/g, '');
    },
  };
}
