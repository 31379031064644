import Mark from 'mark.js/dist/mark.es6.min.js';

export default function highlight(term = '') {
  return {
    init() {
      const highlighter = new Mark(this.$root);
      highlighter.mark(term);
    },
  };
}
