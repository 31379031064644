export default function skiplinks() {
  return {
    skipToMain() {
      document.getElementById('main').scrollIntoView();

      // the first input field can not be of type hidden, because these can not be focused
      // if the first input field is replaced by tinymce, focus needs to be set on the active editor
      const focusable = document.getElementById('main').querySelector('button, [href], input:not([type=hidden]), select, textarea');
      if (focusable.hasAttribute('data-tinymce')) { tinymce.activeEditor.focus() } else { focusable.focus() };
    },
  }
}
