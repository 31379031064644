export default function formErrorHandler() {
  return {

    init() {
      document.addEventListener('turbo:before-stream-render', (e) => {
        // targetId is the target dom id of the turbo_stream
        const targetId = e.detail.newStream.target;
        if (targetId == 'input__error') {
          this.removeStyling();
          this.removeInputErrorMessages();
        } else {
          this.addStyling(targetId);
          this.focusFirstError();
        }
      });
    },

    addStyling(targetId) {
      const element = document.getElementById(targetId);
      const type = Array.from(element.classList).filter(klass => !/^form/.test(klass))[0];

      this.findInputElement(element, type)?.classList.add('form__input--invalid');

      if (['string', 'text'].includes(type) && element.querySelector('.tox-tinymce')) {
        element.querySelector('.tox-tinymce').style.borderColor = 'red';
      }
    },

    removeStyling() {
      const stylingElements = document.querySelectorAll('.form__input--invalid');

      stylingElements.forEach((element) => {
        element.classList.remove('form__input--invalid');
        if (element.querySelector('.tox-tinymce')) {
          element.parentNode.querySelector('.tox-tinymce').style.borderColor = '';
        };
      });
    },

    removeInputErrorMessages() {
      const messages = document.querySelectorAll('.input__error');

      messages.forEach((message) => {
        message.remove();
      });
    },

    focusFirstError() {
      const errorElement = document.querySelector('.form__input--invalid');

      if (errorElement) {
        if (errorElement.parentNode.classList.contains('form-field__file-upload') ||
          errorElement.classList.contains('map-entry') ||
          errorElement.classList.contains("string__input") ||
          errorElement.classList.contains("text__input")) {
          errorElement.parentNode.scrollIntoView();
        } else {
          errorElement.focus();
        }
      }
    },

    findInputElement(element, type) {
      switch (type) {
        case 'devise':
          return element.getElementsByTagName('input')[0];
        case 'string':
          return element.querySelector('input[type=text]') ||
            element.querySelector('input[type=password]') ||
            element.querySelector('input[type=email]') ||
            element.querySelector('input[type=confirmation]');
        case 'text':
          return element.getElementsByTagName('textarea')[0];
        case 'select':
          return element.getElementsByTagName('select')[0];
        case 'checkbox__wrapper':
          return element.querySelector('input[type=checkbox]');
        case 'checkboxes':
          return element.querySelector('input[type=checkbox]');
        case 'radio':
          return element.querySelector('input[type=radio]');
        case 'file-upload':
          return element.querySelector('input[type=file]');
        case 'map-select':
          return element.getElementsByClassName('map-entry')[0];
      }
    },
  };
}
