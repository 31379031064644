export default function dropdown() {
  return {
    currentFocus: -1,
    items: this.$el.querySelectorAll('[role="menuitem"]'),
    trigger: this.$el.querySelector('summary'),

    init() {
      this.$el.parentElement.addEventListener('mouseleave', () => {
        this.$el.open = false;
      });
    },

    previousDropdownItem(items) {
      if (this.currentFocus > 0) {
        items[this.currentFocus].classList.remove('dropdown__item--focus');

        this.currentFocus--;

        items[this.currentFocus].classList.add('dropdown__item--focus');
      }
    },

    nextDropdownItem(items) {
      if (this.trigger.matches(':focus')) this.trigger.classList.add('summary--unfocused');
      if (this.currentFocus < items.length - 1) {
        if (this.currentFocus > -1) items[this.currentFocus].classList.remove('dropdown__item--focus');

        this.currentFocus++;

        items[this.currentFocus].classList.add('dropdown__item--focus');
      }
    },

    resetFocus(items) {
      if (items[this.currentFocus]) items[this.currentFocus].classList.remove('dropdown__item--focus');
      this.trigger.classList.remove('summary--unfocused');
      this.currentFocus = -1;
    },

    dropdown: {
      ['@mouseover']() {
        window.innerWidth > 1024 ? (this.$el.open = true) : null;
      },
      ['@click.outside']() {
        this.$el.open = false;
      },
      ['@keydown.arrow-up.prevent']() {
        this.previousDropdownItem(this.items);
      },
      ['@keydown.arrow-down.prevent']() {
        this.nextDropdownItem(this.items);
      },
      ['@keydown.tab']() {
        this.resetFocus(this.items);
        this.$el.open = false;
      },
      ['@keydown.space']() {
        if (this.$el.open) {
          this.resetFocus(this.items);
        }
      },
      ['@keydown.enter']() {
        if (this.currentFocus > -1) {
          this.items[this.currentFocus].click();
          this.resetFocus(this.items);
        }
      },
    },
  };
}
