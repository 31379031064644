const flashWrapper = document.querySelector("[data-js-selector='flash-wrapper']");
const closeButton = document.querySelector("[data-id='close-flash-message']");

const removeFlashMessage = () => flashWrapper.removeChild(flashWrapper.children[0]);

const focusFirstElement = () => {
  const main = document.querySelector('#main');
  const focusable = main.querySelectorAll(
    "button, [href], input:not([type='hidden']), select, textarea, [tabindex]:not([tabindex='-1'])"
  );
  const firstFocusable = focusable[0];
  firstFocusable.focus();
};

closeButton?.addEventListener('keypress', (e) => {
  if (e.key !== 'Enter') return;

  try {
    removeFlashMessage();
    focusFirstElement();
  } catch (error) {
    console.error(error);
  }
});

closeButton?.addEventListener('click', () => {
  try {
    removeFlashMessage();
  } catch (error) {
    console.error(error);
  }
});
